// Holloween Trolley Icons
export const hweenTrolleyIcon = (iconHex: string) => {
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <path
      d="M17.31,7.88a.89.89,0,0,0-1-.72c-.63,0-1.45.1-2.24.14a.82.82,0,0,1-.84-.72C13,4.13,12.54,1.2,9.67,1.17c-3.3,0-4.75,3-5.26,5.48a.8.8,0,0,1-.84.63c-.69,0-1.37-.09-1.91-.12a.87.87,0,0,0-.6,1.59,16.27,16.27,0,0,0,2.72,1.56.81.81,0,0,1,.47.67,24.58,24.58,0,0,1-.42,4.19.82.82,0,0,0,1.3.78,2.58,2.58,0,0,1,1.28-.59c1.08,0,1.89,1.56,3.15,1.47s2-1.68,3.12-1.73a2.05,2.05,0,0,1,1.12.31A.82.82,0,0,0,15,14.47c-.36-1.34-.71-2.79-.89-3.32a.79.79,0,0,1,.42-1,21.07,21.07,0,0,0,2.44-1.44A.88.88,0,0,0,17.31,7.88ZM9.68,8"
      stroke="#ffffff" stroke-width="1.33px" />
    <path
      d="M17.31,7.88a.89.89,0,0,0-1-.72c-.63,0-1.45.1-2.24.14a.82.82,0,0,1-.84-.72C13,4.13,12.54,1.2,9.67,1.17c-3.3,0-4.75,3-5.26,5.48a.8.8,0,0,1-.84.63c-.69,0-1.37-.09-1.91-.12a.87.87,0,0,0-.6,1.59,16.27,16.27,0,0,0,2.72,1.56.81.81,0,0,1,.47.67,24.58,24.58,0,0,1-.42,4.19.82.82,0,0,0,1.3.78,2.58,2.58,0,0,1,1.28-.59c1.08,0,1.89,1.56,3.15,1.47s2-1.68,3.12-1.73a2.05,2.05,0,0,1,1.12.31A.82.82,0,0,0,15,14.47c-.36-1.34-.71-2.79-.89-3.32a.79.79,0,0,1,.42-1,21.07,21.07,0,0,0,2.44-1.44A.88.88,0,0,0,17.31,7.88ZM9.68,8"
      stroke="#000000" stroke-width="0.5px" fill=${iconHex} />
    <ellipse cx="10.42" cy="5.27" rx="0.74" ry="1.21" stroke="#000000"
      stroke-width="0.5px"
      fill="#ffffff" />
    <ellipse cx="7.48" cy="5.27" rx="0.74" ry="1.21" stroke="#000000"
      stroke-width="0.5px"
      fill="#ffffff" />
    <circle cx="8.95" cy="7.95" r="0.74" stroke="#000000" stroke-width="0.5px"
      fill="#ffffff" />
  </svg>`;
};
