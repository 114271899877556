import axios from "axios";
import { useQuery } from "react-query";
import { ClipLoader } from "react-spinners";

import "./ChapelAttendance.css";

// 3 differant staus
//  percent (in API)
//  Good(Green) / Warning(Yellow) / Danger(Red)
//  (Must attend 3 a week)
//  Attend _ of _ remaning

const endpoint =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api/v1/users"
    : "/api/v1/users";

const api = axios.create({
  baseURL: `${endpoint}/chapel`,
  timeout: 1000,
});

// Attempt to handle 401 auth errors
api.interceptors.response.use(undefined, (error) => {
  if (error.status === 401) {
    window.location.reload();
  }
  return Promise.reject(new Error(error));
});

const fetchUserChapel = async () => {
  const res = await api.get(`/`, {
    validateStatus: function (status) {
      return status < 300;
    },
  });

  return res.data;
};

// days is an array of weekdays: 0 is Sunday, ..., 6 is Saturday
const countCertainDays = (days: [], d0: Date, d1: Date) => {
  const ndays =
    1 + Math.round((d1.getTime() - d0.getTime()) / (24 * 3600 * 1000));
  const sum = (a: number, b: number) => {
    return a + Math.floor((ndays + ((d0.getDay() + 6 - b) % 7)) / 7);
  };
  return days.reduce(sum, 0);
};

// Create componets for reuse
const FullTimeReq = () => {
  return (
    <li>
      <b>Full Time</b> (12 or more units)
      <br />
      About 3 chapels a week
    </li>
  );
};

const AccommReq = () => {
  return (
    <li>
      <b>Accommodations</b> <br />
      (Chapel Accommodations Form)
      <br />
      Staff approved requirement
    </li>
  );
};

const PartTimeReq = () => {
  return (
    <li>
      <b>Accommodations / Part Time</b>
      <br />
      (11 or less units)
      <br />
      No requirement
    </li>
  );
};

// Main component
const ChapelAttendance = () => {
  // const [creditReq, setCreditReq] = useState(36);
  // Set Defaults
  let creditsEarned, trimPeriodName;
  let totalCreditReq = 36;

  let requireBlock = (
    <>
      <FullTimeReq />
      <AccommReq />
      <PartTimeReq />
    </>
  );

  // Query our Api for the user data
  const { isSuccess, isLoading, data } = useQuery(
    "userChapel",
    fetchUserChapel,
  );

  // Change data based on success of api call
  if (isLoading) {
    creditsEarned = (
      <div id="loader">
        <ClipLoader color={"#700"} size={30} />
        <br />
      </div>
    );
  } else if (
    isSuccess &&
    data.userCreditData?.creditsEarned &&
    data.userCreditData?.creditsRequired >= 0 &&
    data.periodData?.periodName
  ) {
    totalCreditReq = data.periodData.numberRequired;
    const userCreditReq = data.userCreditData.creditsRequired;
    // Set Credits out of display
    creditsEarned = (
      <>
        <h3>{data.userCreditData.creditsEarned}</h3>
        <h4>of</h4>
        <h3>{userCreditReq}</h3>
      </>
    );
    // Set title
    trimPeriodName = data.periodData.periodName.replace("APU Chapel", "");

    // Set info base on infered accomidations
    if (data.userCreditData.creditsRequired >= totalCreditReq) {
      requireBlock = <FullTimeReq />;
    } else if (data.userCreditData.creditsRequired === 0) {
      requireBlock = <PartTimeReq />;
    } else if (data.userCreditData.creditsRequired < totalCreditReq) {
      requireBlock = <AccommReq />;
    }
  } else {
    creditsEarned = <p>Error Loading Data</p>;
  }

  return (
    <div id="chapel-attend">
      <div id="content">
        <div className="header">
          <h1>Chapel</h1>
          <h5>{trimPeriodName}</h5>
        </div>
        <div className="shade-back">
          <h2>Credits Earned</h2>
          <div className="totals">{creditsEarned}</div>
        </div>
        <div id="subtext">
          Please report any credit issues
          <br />
          to Chapel staff within 2 business days
        </div>
        <div className="shade-back" id="info">
          <h2>Student Requirement</h2>
          <ul>{requireBlock}</ul>
        </div>
        <div className="shade-back">
          <div id="credit-resources">
            <h2>Credit Resources</h2>
            <ul>
              <li>
                <a href="https://www.apu.edu/live/">Chapel Livestream</a>
              </li>
              <li>
                <a href="https://www.youtube.com/c/AzusaPacificUniversityChapels">
                  Chapel Video Archive
                </a>
              </li>
              <li>
                <a href="https://www.apu.edu/spiritual-life/requirements/">
                  Chapel Policy
                </a>
              </li>
              <li>
                <a href="https://www.apu.edu/spiritual-life/requirements/">
                  Chapel Requirements
                </a>
              </li>
              <li>
                <a href="https://formstack.apu.edu/forms/pastoral_counseling_appointment_ac22">
                  Pastoral Counseling Appt. Form
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChapelAttendance;
