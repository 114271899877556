// APU Logos
import LogoHzNormPNG from "#images/APUlogos/apu-logo-h.png";
import LogoHzWhNormPNG from "#images/APUlogos/apu-logo-h-w.png";
import LogoHzNormSVG from "#images/APUlogos/apu-logo.svg";
import LogoHzSplPNG from "#images/APUlogos/apu-logo-h-spl.png";
import LogoHzWhSplPNG from "#images/APUlogos/apu-logo-h-w-spl.png";
import LogoHzSplSVG from "#images/APUlogos/apu-logo-spl.svg";
import LogoStkNormPNG from "#images/APUlogos/apu-logo-s.png";
import LogoStkSplPNG from "#images/APUlogos/apu-logo-s-spl.png";
import LogoStkWhPNG from "#images/APUlogos/apu-logo-s-w.png";
import LogoStkWhSplPNG from "#images/APUlogos/apu-logo-s-w-spl.png";

// Set Logo to Normal or Special logo
const useSpecialLogo = true;
// Logo Object
export const APULogoObject = {
  hzSVG: useSpecialLogo ? LogoHzSplSVG : LogoHzNormSVG,
  hzPNG: useSpecialLogo ? LogoHzSplPNG : LogoHzNormPNG,
  hzWhPNG: useSpecialLogo ? LogoHzWhSplPNG : LogoHzWhNormPNG,
  stkPNG: useSpecialLogo ? LogoStkSplPNG : LogoStkNormPNG,
  stkWhPNG: useSpecialLogo ? LogoStkWhSplPNG : LogoStkWhPNG,
};
