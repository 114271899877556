import "./OopsPage.css";

const OopsPage = () => {
  return (
    <div id="center">
      <p className="oops">Oops!</p>
      <p className="oops-detail">
        Something went wrong! Please try refreshing this page.
      </p>
      <p className="oops-detail">
        If you continue to receive this error, please contact the IMT Support
        Center.
      </p>
      <p className="oops-detail">
        Online at{" "}
        <a href="https://support.apu.edu/hc/en-us/requests/new">
          support.apu.edu
        </a>
      </p>
      <p className="oops-detail">
        By email at <a href="mailto:support@apu.edu">support@apu.edu</a>
      </p>
      <p className="oops-detail">
        Or by phone at <a href="tel:+16268155050">(626) 815-5050</a>
      </p>
    </div>
  );
};

export default OopsPage;
