import React from "react";
import { Link } from "react-router-dom";

const ResourcePageMenu = () => {
  return (
    <body className="stylized-index-page" id="resources-pages-index">
      <h1>Resources Pages</h1>
      <ul>
        <li>
          <Link to="/resource-pages?page=applicantData">Applicant</Link>
        </li>
        <li>
          <Link to="/resource-pages?page=employeeData">Employee</Link>
        </li>
        <li>
          <Link to="/resource-pages?page=facultyData">Faculty</Link>
        </li>
        <li>
          <Link to="/resource-pages?page=generalData">General</Link>
        </li>
        <li>
          <Link to="/resource-pages?page=gradProData">Grad Pro</Link>
        </li>
        <li>
          <Link to="/resource-pages?page=oneStopData">One Stop</Link>
        </li>
        <li>
          <Link to="/resource-pages?page=studentInfoData">Student Info</Link>
        </li>
        <li>
          <Link to="/resource-pages?page=ugStudentData">UG Student</Link>
        </li>
      </ul>
    </body>
  );
};

export default ResourcePageMenu;
