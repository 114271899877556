const allPageData = {
  applicantData: [
    {
      title: "Academics",
      content: [
        {
          item: "How to register for class",
          link: "https://support.apu.edu/hc/en-us/articles/360002859154-How-to-register-for-classes-using-APU-Mobile?apu_src=applicant_page",
        },
        {
          item: "Bookstore",
          link: "https://www.bookstore.apu.edu/?apu_src=applicant_page",
        },
        {
          item: "Library Resources",
          link: "https://www.apu.edu/library/resources?apu_src=applicant_page",
        },
      ],
    },
    {
      title: "Student Resources",
      content: [
        {
          item: "MyCougar Health",
          link: "https://apu.medicatconnect.com/?apu_src=applicant_page",
        },
        {
          item: "Student Government Association (SGA)",
          link: "https://apusga.com/?apu_src=applicant_page",
        },
      ],
    },
    {
      title: "Shopping",
      content: [
        {
          item: "Campus Store",
          link: "https://campusstore.apu.edu/?apu_src=applicant_page",
        },
      ],
    },
    {
      title: "Connect with APU",
      content: [
        {
          item: "Facebook",
          link: "https://www.facebook.com/azusapacific?apu_src=applicant_page",
        },
        {
          item: "Instagram",
          link: "https://www.instagram.com/azusapacific?apu_src=applicant_page",
        },
        {
          item: "Pinterest",
          link: "https://www.pinterest.com/azusapacific?apu_src=applicant_page",
        },
        {
          item: "X (Twitter)",
          link: "https://x.com/azusapacific?apu_src=applicant_page",
        },
        {
          item: "YouTube",
          link: "https://www.youtube.com/user/UniversityRelations?apu_src=applicant_page",
        },
      ],
    },
  ],
  employeeData: [
    {
      title: "Employee Resources",
      content: [
        {
          item: "HR Total Access",
          link: "https://hrtotalaccess.apu.edu/hr/totalaccess/index.cfm?apu_src=employee_page",
        },
        {
          item: "Benefits Enrollment/Update",
          link: "https://hrtotalaccess.apu.edu/hr/totalaccess/index.cfm?switch=benefits&menu=mi&apu_src=employee_page",
        },
        {
          item: "APU C.A.R.E.S.",
          link: "https://apucares.apu.edu/?pslnkid=APU_CARES_REF&apu_src=employee_page",
        },
        {
          item: "Timekeeping (Kronos)",
          link: "https://timekeeping.apu.edu/?apu_src=employee_page",
        },
        {
          item: "Office of Institutional Research (OIR) Data Request Form",
          link: "https://formstack.apu.edu/forms/apu_data_request_form?apu_src=employee_page",
        },
      ],
    },
    {
      title: "IT",
      content: [
        {
          item: "IT Support Center",
          link: "https://support.apu.edu/?apu_src=employee_page/",
        },
      ],
    },
  ],
  facultyData: [
    {
      beforeLink: [
        {
          item: "Stellic",
          link: "https://stellic.apu.edu/?apu_src=faculty_page",
        },
        {
          item: "APU CARES",
          link: "https://formstack.apu.edu/forms/apucareteam?apu_src=faculty_page",
        },
        {
          item: "Accessibility and Disability",
          link: "https://shibboleth-apu-accommodate.symplicity.com/sso/ADRAccessFaculty?apu_src=faculty_page",
        },
      ],
      title: "Communications from the Provost",
      content: [
        {
          item: "Provost Newsletter Archives",
          link: "https://sites.google.com/apu.edu/officeoftheprovostnewsletter/archives?apu_src=faculty_page",
        },
        {
          item: "Program Fiscal Viability Analysis - FY 22",
          link: "https://drive.google.com/file/d/1_5t7ZAaJUcHOjOJy3jk1Og3keFSfGKOV/view?usp=sharing&apu_src=faculty_page",
        },
      ],
    },
    {
      title: "Faculty Menu PeopleSoft",
      content: [
        {
          item: "Faculty Center",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/PRODSTU/c/SA_LEARNING_MANAGEMENT.SS_FACULTY.GBL?apu_src=faculty_page",
        },
        {
          item: "Student Services Center",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/HRMS/c/SCC_ADMIN_OVRD_STDNT.SSS_STUDENT_CENTER.GBL?apu_src=faculty_page",
        },
        {
          item: "Request Advisement Report",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/PRODSTU/c/DEFINE_ACADEMIC_REQUIREMENTS.SAA_RPT_RQST.GBL?apu_src=faculty_page",
        },
        {
          item: "Faculty Class Roster Instructions ",
          link: "https://docs.google.com/document/d/1AfDj4wOnFlESKYF27-2_ZiEr9w_zpykjeiP2-0hbuJo/edit?usp=sharing&apu_src=faculty_page",
        },
      ],
    },
    {
      title: "Grade Rosters & Grade Submission",
      content: [
        {
          item: "Faculty Grading Instructions - Quick Reference",
          link: "https://docs.google.com/document/d/1oVkSFIlsfBVvsbStJ_ynDQr-gcIFqIUAqdYezL5Rj2g?apu_src=faculty_page",
        },
        {
          item: "Faculty Grading Instructions - Expanded Version",
          link: "https://docs.google.com/document/d/1yBVYVRIAy6P4ExV-h_0WaBiaADmAxTiB1-i3aHkTqG0?apu_src=faculty_page",
        },
        {
          item: "Traditional Undergraduate Grade Change",
          link: "https://formstack.apu.edu/forms/tug_grade_change?apu_src=faculty_page",
        },
        {
          item: "Graduate and Professional Grade Change",
          link: "https://formstack.apu.edu/workflows/gp_grade_change?apu_src=faculty_page",
        },
      ],
    },
    {
      title: "IDEA & Interfolio",
      content: [
        {
          item: "Learning Essentials Request",
          link: "https://docs.google.com/a/apu.edu/forms/d/1Xz5-g2z_14P7LbKKARkpNdCPY7tvSCMNLq3xXSJ7Pks/viewform?pslnkid=ADMN_LINK_FAC_IDEA_LE&apu_src=faculty_page",
        },
        {
          item: "FES - Interfolio",
          link: "https://iam-api.interfolio.com/users/sso_redirect?tenant_id=32268&redirect_url=https://home.interfolio.com/32268&apu_src=faculty_page",
        },
        {
          item: "Faculty IDEA Dashboard",
          link: "https://apu.campuslabs.com/faculty?apu_src=faculty_page",
        },
      ],
    },
    {
      title: "Tech Teaching Tips (videos)",
      content: [
        {
          item: "Using APU Classrooms for Remote Teaching",
          link: "https://www.youtube.com/watch?v=YbLJN8Ida7k&list=PLshNjHxGe54wtUIhRAKz6Fv5WVsjRjY4E&index=1&apu_src=faculty_page",
        },
        {
          item: "Webcam Placement & Zoom Screen Sharing from a Laptop",
          link: "https://www.youtube.com/watch?v=Nxy7-XdQ_4g&list=PLshNjHxGe54wtUIhRAKz6Fv5WVsjRjY4E&index=3&apu_src=faculty_page",
        },
        {
          item: "HyFlex Tutorial for Conference Rooms",
          link: "https://www.youtube.com/watch?v=ZaODTU2iy6s&list=PLshNjHxGe54wtUIhRAKz6Fv5WVsjRjY4E&index=2&apu_src=faculty_page",
        },
        {
          item: "How to Use Zoom's Dual Monitor Mode",
          link: "https://www.youtube.com/watch?v=aiwiQTwgHy0&list=PLshNjHxGe54wtUIhRAKz6Fv5WVsjRjY4E&index=4&apu_src=faculty_page",
        },
        {
          item: "How to Record & Upload Zoom Meetings to Google Drive and YouTube",
          link: "https://www.youtube.com/watch?v=EsHMwwAfv1Q&list=PLshNjHxGe54wtUIhRAKz6Fv5WVsjRjY4E&index=5&apu_src=faculty_page",
        },
        {
          item: "How to Use the Huddly Image Control App for Whiteboarding",
          link: "https://www.youtube.com/watch?v=4W8CPBG8W-A&list=PLshNjHxGe54wtUIhRAKz6Fv5WVsjRjY4E&index=6&apu_src=faculty_page",
        },
      ],
    },
  ],
  generalData: [
    {
      title: "Academics",
      content: [
        {
          item: "Calendars & Schedules",
          link: "https://www.apu.edu/calendar?apu_src=general_page",
        },
        {
          item: "Library Resources",
          link: "https://www.apu.edu/library/resources?apu_src=general_page",
        },
        {
          item: "Forms and Publication",
          link: "https://den.apu.edu/forms?apu_src=general_page",
        },
        {
          item: "FES - Interfolio",
          link: "https://iam-api.interfolio.com/users/sso_redirect?tenant_id=32268&redirect_url=https://home.interfolio.com/32268&apu_src=general_page",
        },
        {
          item: "IDEA Course Evaluation",
          link: "https://apu.campuslabs.com/faculty?apu_src=general_page",
        },
      ],
    },
    {
      title: "Employee Resources",
      content: [
        {
          item: "Reset Your Password",
          link: "https://den.apu.edu/idm/passwordResetIdentification.action?apu_src=general_page",
        },
        {
          item: "Change Your Password",
          link: "https://den.apu.edu/idm/secure/password.change.action?apu_src=general_page",
        },
        {
          item: "Change Role Account Password",
          link: "https://adpassword.apu.edu/?apu_src=general_page",
        },
        {
          item: "Timekeeping (Kronos)",
          link: "https://timekeeping.apu.edu/?apu_src=general_page",
        },
        {
          item: "HR Total Access",
          link: "https://hrtotalaccess.apu.edu/hr/totalaccess/index.cfm?apu_src=general_page",
        },
        {
          item: "APU C.A.R.E.S.",
          link: "https://apucares.apu.edu/?pslnkid=APU_CARES_REF&apu_src=general_page",
        },
        {
          item: "Benefits Enrollment/Update",
          link: "https://workforcenow.adp.com/im?apu_src=general_page",
        },
        {
          item: "Office of Institutional Research (OIR) Data Request Form",
          link: "https://formstack.apu.edu/forms/apu_data_request_form?apu_src=general_page",
        },
      ],
    },
    {
      title: "Campus Resources",
      content: [
        {
          item: "IT Support Center",
          link: "https://support.apu.edu/?apu_src=general_page",
        },
        {
          item: "Campus Store",
          link: "https://campusstore.apu.edu/?apu_src=general_page",
        },
        {
          item: "APU Events - 25Live",
          link: "https://25live.collegenet.com/apu?apu_src=general_page",
        },
        {
          item: "Athletics at APU",
          link: "https://athletics.apu.edu/?apu_src=general_page",
        },
        {
          item: "APU Talk",
          link: "https://docs.google.com/a/apu.edu/forms/d/e/1FAIpQLSdORXGriWPo0A5m-FHZJaERUQxyWm0hoeb7tvPCDHmsJYdcIg/viewform?apu_src=general_page",
        },
        {
          item: "Campus Map",
          link: "https://www.apu.edu/map?apu_src=general_page",
        },
      ],
    },
    {
      title: "Student Resources",
      content: [
        {
          item: "Handshake Job Platform",
          link: "https://app.joinhandshake.com/login?apu_src=general_page",
        },
        {
          item: "Faculty Directory",
          link: "https://www.apu.edu/faculty/?apu_src=general_page",
        },
        {
          item: "Service Credits",
          link: "https://www.centerforstudentaction.org/?apu_src=general_page",
        },
        {
          item: "Student Handbook",
          link: "https://www.apu.edu/live_data/files/165/student_handbook_201516.pdf?apu_src=general_page",
        },
        {
          item: "Student Government Association (SGA)",
          link: "https://apusga.com/?apu_src=general_page",
        },
        {
          item: "My IRBManager",
          link: "https://apu.my.irbmanager.com/?apu_src=general_page",
        },
        {
          item: "MyCougar Health",
          link: "https://apu.medicatconnect.com/?apu_src=general_page",
        },
      ],
      afterLink: [
        {
          item: "APU Mobile Apps",
          link: "https://www.apu.edu/mobile/#mobile?apu_src=general_page",
        },
      ],
    },
  ],
  gradProData: [
    {
      title: "APU Support Services",
      content: [
        {
          item: "Career Services",
          link: "https://www.apu.edu/career?apu_src=graduate_and_professional_page",
        },
        {
          item: "Counseling Services",
          link: "https://www.apu.edu/counselingcenter?apu_src=graduate_and_professional_page",
        },
        {
          item: "Disability Services",
          link: "https://www.apu.edu/lec/disabilities?apu_src=graduate_and_professional_page",
        },
        {
          item: "Diversity Office",
          link: "https://www.apu.edu/diversity?apu_src=graduate_and_professional_page",
        },
        {
          item: "IT Support Center",
          link: "https://www.apu.edu/imt/support?apu_src=graduate_and_professional_page",
        },
        {
          item: "International Student Services",
          link: "https://www.apu.edu/international?apu_src=graduate_and_professional_page",
        },
        {
          item: "Military & Veterans Resources",
          link: "https://www.apu.edu/militaryeducation?apu_src=graduate_and_professional_page",
        },
        {
          item: "Writing Support",
          link: "https://www.apu.edu/writingcenter?apu_src=graduate_and_professional_page",
        },
        {
          item: "Graduate & Professional Student Government Association (GPSGA)",
          link: "https://gpsga.apu.edu/?apu_src=graduate_and_professional_page",
        },
      ],
    },
    {
      title: "Learning Resources",
      content: [
        {
          item: "Library Resources",
          link: "https://www.apu.edu/library/resources?apu_src=graduate_and_professional_page",
        },
        {
          item: "Bookstore",
          link: "https://www.bookstore.apu.edu/?apu_src=graduate_and_professional_page",
        },
        {
          item: "Canvas",
          link: "https://canvas.apu.edu/login/cas?apu_src=graduate_and_professional_page",
        },
        {
          item: "IDEA Course Evaluation",
          link: "https://apu.campuslabs.com/courseeval/?apu_src=graduate_and_professional_page",
        },
      ],
    },
    {
      title: "Graduate & Professional Center",
      content: [
        {
          item: "Graduate & Professional Center",
          link: "https://www.apu.edu/graduateprofessionalcenter?apu_src=graduate_and_professional_page",
        },
        {
          item: "Graduate Admissions",
          link: "https://www.apu.edu/admissions/#graduate?apu_src=graduate_and_professional_page",
        },
        {
          item: "Graduate Registrar",
          link: "https://www.apu.edu/graduateprofessionalcenter/registrar?apu_src=graduate_and_professional_page",
        },
        {
          item: "Graduate Student Financial Services",
          link: "https://www.apu.edu/graduateprofessionalcenter/sfs?apu_src=graduate_and_professional_page",
        },
        {
          item: "Graduate & Professional Student Affairs",
          link: "https://www.apu.edu/graduateprofessionalcenter/student-engagement/?apu_src=graduate_and_professional_page",
        },
        {
          item: "Alumni Association and ID Card",
          link: "https://www.apu.edu/alumni/benefits/?apu_src=graduate_and_professional_page",
        },
      ],
    },
    {
      title: "Campus Safety and Facilities",
      content: [
        {
          item: "Campus Safety",
          link: "https://www.apu.edu/campussafety/contact?apu_src=graduate_and_professional_page",
        },
        {
          item: "Graduate ID Card",
          link: "https://www.apu.edu/auxiliary-services/onecardoffice/#student?apu_src=graduate_and_professional_page",
        },
        {
          item: "Housing",
          link: "https://www.apu.edu/asset/students?apu_src=graduate_and_professional_page",
        },
        {
          item: "Student Parking",
          link: "https://www.apu.edu/graduateprofessionalcenter/resources/parking?apu_src=graduate_and_professional_page",
        },
      ],
    },
    {
      title: "Services on the Azusa Campus",
      content: [
        {
          item: "Campus Store",
          link: "https://computerstore.apu.edu/?apu_src=graduate_and_professional_page",
        },
        {
          item: "Campus Dining",
          link: "https://apu.cafebonappetit.com/?pslnkid=APU_CAMPUS_DINING_LNK&apu_src=graduate_and_professional_page",
        },
        {
          item: "Azusa Print + Design",
          link: "https://www.apu.edu/campusauxiliary/printanddesign?apu_src=graduate_and_professional_page",
        },
        {
          item: "Fitness Center",
          link: "https://www.apu.edu/fitnesscenter?apu_src=graduate_and_professional_page",
        },
      ],
    },
    {
      title: "Graduate Health Insurance",
      content: [
        {
          item: "Enroll in Health Insurance",
          link: "https://www.apu.edu/graduateprofessionalcenter/resources/healthinsurance?apu_src=graduate_and_professional_page",
        },
        {
          item: "Gallagher Student Health",
          link: "https://www.gallagherstudent.com/students/student-home.php?idField=1255&apu_src=graduate_and_professional_page",
        },
      ],
    },
    {
      title: "Student Housing",
      content: [
        {
          item: "How to Sign Up for Campus Housing",
          link: "https://www.apu.edu/housing/signup/?apu_src=graduate_and_professional_page",
        },
        {
          item: "Campus Housing Room Condition - 2024-2025",
          link: "https://formstack.apu.edu/forms/rca_2024_2025/?apu_src=graduate_and_professional_page",
        },
      ],
      afterLink: [
        {
          item: "Graduate Student Publications",
          link: "https://www.apu.edu/researchandgrants/graduate-student-publications/?apu_src=graduate_and_professional_page",
        },
        {
          item: "Meal Plan Selection",
          link: "https://formstack.apu.edu/forms/dining_plan_sign_up?apu_src=graduate_and_professional_page",
        },
      ],
    },
  ],
  oneStopData: [
    {
      title: "Academic Forms",
      content: [
        {
          item: "Change my Major",
          link: "https://formstack.apu.edu/workflows/major_update?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Change my Minor",
          link: "https://formstack.apu.edu/workflows/minor_update?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Request for Additional Units",
          link: "https://formstack.apu.edu/workflows/request_for_additional_units?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Transfer a Course From Another Institution",
          link: "https://www.apu.edu/undergraduate-enrollment-services/academic/transfer/?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Enrollment Verification",
          link: "https://formstack.apu.edu/forms/enrollment_verification_workflow_?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "GPA Verification",
          link: "https://formstack.apu.edu/forms/gpa_verification_workflow_?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Degree Verification",
          link: "https://formstack.apu.edu/forms/degree_verification_workflow_?apu_src=ug_enrollment_services_center_page",
        },
      ],
    },
    {
      title: "My Financial Aid & Student Account",
      content: [
        {
          item: "Complete MPN (Direct Loans)",
          link: "https://studentloans.gov/myDirectLoan/index.action?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Complete FAFSA",
          link: "https://fafsa.ed.gov/?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Complete Entrance Counseling (Direct Loans)",
          link: "https://studentloans.gov/myDirectLoan/index.action?pslnkid=APU_OS_STAFFORDENTRANCE&apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Apply for a Parent Plus Loan",
          link: "https://apu.edu/undergraduate-enrollment-services/finances/aid/loans/?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Apply for an Alternative (Private) Loan",
          link: "https://www.elmselect.com/#/?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Financial Aid Forms",
          link: "https://www.apu.edu/undergraduate-enrollment-services/finances/?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "View my Federal Loan Summary",
          link: "https://www.nslds.ed.gov/nslds/nslds_SA/?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Complete my Loan Exit",
          link: "https://www.nslds.ed.gov/nslds/nslds_SA/?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "View Outside Scholarships",
          link: "https://www.fastweb.com/college-scholarships?apu_src=ug_enrollment_services_center_page",
        },
      ],
    },
    {
      title: "Connect with Undergraduate Enrollment Services Center",
      content: [
        {
          item: "Undergraduate Enrollment Services Center Website",
          link: "https://www.apu.edu/undergraduate-enrollment-services?apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Undergraduate Enrollment Services Center Email",
          link: "https://mail.google.com/mail/?view=cm&fs=1&to=uesc@apu.edu&apu_src=ug_enrollment_services_center_page",
        },
        {
          item: "Help Center",
          link: "https://sites.google.com/a/apu.edu/studenthelpcenter/?apu_src=ug_enrollment_services_center_page",
        },
      ],
      afterLink: [
        {
          item: "Leave of Absence & Withdrawal",
          link: "https://www.apu.edu/undergraduate-enrollment-services/finances/policies/#refundpolicyandwithdrawalinformation?apu_src=ug_enrollment_services_center_page",
        },
      ],
    },
  ],
  studentInfoData: [
    {
      title: "Campus Community",
      content: [
        {
          item: "Add/Update a Person (Student Info)",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/HRMS/c/CC_BIO_DEMO_DATA.SCC_BIO_DEMO.GBL?apu_src=student_info_page",
        },
        {
          item: "Student Services Center (Student Info)",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/HRMS/c/SCC_ADMIN_OVRD_STDNT.SSS_STUDENT_CENTER.GBL?apu_src=student_info_page",
        },
        {
          item: "Manage Service Indicator (Student Info)",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/PRODSTU/c/MAINTAIN_SERVICE_IND_STDNT.ACTIVE_SRVC_INDICA.GBL?apu_src=student_info_page",
        },
      ],
    },
    {
      title: "Curriculum Management",
      content: [
        {
          item: "Class Roster (Student Info)",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/HRMS/c/ESTABLISH_COURSES.CLASS_ROSTER.GBL?apu_src=student_info_page",
        },
        {
          item: "Grade Roster (Student Info)",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/HRMS/c/ESTABLISH_COURSES.GRADE_ROSTER.GBL?apu_src=student_info_page",
        },
        {
          item: "Instructor Schedule (Student Info)",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/HRMS/c/ESTABLISH_COURSES.INSTR_CLASS.GBL?apu_src=student_info_page",
        },
        {
          item: "Class Search (Student Info)",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/SA/c/ESTABLISH_COURSES.CLASS_SEARCH.GBL?apu_src=student_info_page",
        },
      ],
    },
    {
      title: "Student Financials",
      content: [
        {
          item: "View Customer Accounts (Student Info)",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/HRMS/c/MAINTAIN_CUSTOMERS.ACCOUNT_VW.GBL?apu_src=student_info_page",
        },
        {
          item: "View Items Due (Student Info)",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/HRMS/c/MAINTAIN_RECEIVABLES.ITEM_DUE_SF.GBL?apu_src=student_info_page",
        },
      ],
    },
    {
      title: "Student Admin Docs",
      content: [
        {
          item: "Ark - University Document Repository (Student Info)",
          link: "https://ark.apu.edu/share/page/?apu_src=student_info_page",
        },
        {
          item: "How to Request a Transcript Report (Student Info)",
          link: "https://home-services.apu.edu/services/request-transcript-help/?apu_src=student_info_page",
        },
      ],
    },
    {
      title: "Other",
      content: [
        {
          item: "Request Transcript Report (Student Info)",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/PRODSTU/c/MANAGE_ACADEMIC_RECORDS.SSR_TSCRPT_RQST.GBL?apu_src=student_info_page",
        },
        {
          item: "Request Advisement Report (Student Info)",
          link: "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/PRODSTU/c/DEFINE_ACADEMIC_REQUIREMENTS.SAA_RPT_RQST.GBL?apu_src=student_info_page",
        },
      ],
    },
  ],
  ugStudentData: [
    {
      title: "Tutoring Center",
      content: [
        {
          item: "Schedule a Tutoring Center Appointment",
          link: "https://sites.google.com/apu.edu/tutoring/appointment-help/how-to-schedule-appointments?apu_src=ug_student_page",
        },
        {
          item: "View the Tutoring Center Drop In Schedule",
          link: "https://sites.google.com/apu.edu/tutoring/drop-ins?apu_src=ug_student_page",
        },
        {
          item: "View Courses Available for Tutoring",
          link: "https://sites.google.com/apu.edu/tutoring/available-tutoring-courses?apu_src=ug_student_page",
        },
      ],
    },
    {
      title: "Writing Center",
      content: [
        {
          item: "Schedule a Writing Center Appointment",
          link: "https://sites.google.com/apu.edu/tutoring/appointment-help/how-to-schedule-appointments?apu_src=ug_student_page",
        },
        {
          item: "View the Writing Center Drop-In Schedule",
          link: "https://sites.google.com/apu.edu/tutoring/drop-ins?apu_src=ug_student_page",
        },
        {
          item: "View Writing Resources",
          link: "https://sites.google.com/apu.edu/tutoring/writing-resources?apu_src=ug_student_page",
        },
      ],
    },
    {
      title: "Academic Success Center",
      content: [
        {
          item: "Schedule an Academic Advising Appointment",
          link: "https://tutortrac.apu.edu/?apu_src=ug_student_page",
        },
        {
          item: "Math Placement",
          link: "https://www.apu.edu/academic-success/services/testing/math/start-aleks/?wapu_src=ug_student_page",
        },
        {
          item: "Writing Placement",
          link: "https://docs.google.com/forms/d/e/1FAIpQLSeI052TH4HvFpicnDJuxAwaJvN-PLDIqgnx9WarQz8A8BC0iQ/viewform?wapu_src=ug_student_page",
        },
        {
          item: "Foreign Language Placement & Proficiency",
          link: "https://app.emmersion.ai/Login?wapu_src=ug_student_page",
        },
      ],
    },
    {
      title: "Library",
      content: [
        {
          item: "Search",
          link: "https://discovery.ebsco.com/c/cexh2t/modal/advanced-search/?apu_src=ug_student_page",
        },
        {
          item: "Schedule Research Appointment",
          link: "https://www.apu.edu/library/help/?apu_src=ug_student_page",
        },
        {
          item: "Library Home",
          link: "https://www.apu.edu/library/?apu_src=ug_student_page",
        },
      ],
    },
    {
      title: "Student Employment",
      content: [
        {
          item: "Handshake Job Platform",
          link: "https://apu.joinhandshake.com/login?apu_src=ug_student_page",
        },
        {
          item: "Finding Jobs on Campus",
          link: "https://www.apu.edu/studentemployment/working?apu_src=ug_student_page",
        },
        {
          item: "Respect and Esteem Training",
          link: "https://slate.workplaceanswers.com/azusa?apu_src=ug_student_page",
        },
        {
          item: "Learn About Federal Work Study",
          link: "https://www.apu.edu/studentemployment/working/federalworkstudy?apu_src=ug_student_page",
        },
        {
          item: "How to log in to Silk Road",
          link: "https://drive.google.com/file/d/1Y9eNyREoIhiyiRmVqYS-mzPNRPM2T_18/view?apu_src=ug_student_page",
        },
      ],
    },
    {
      title: "Student Housing",
      content: [
        {
          item: "How to Sign Up for Campus Housing",
          link: "https://www.apu.edu/housing/signup/?apu_src=ug_student_page",
        },
        {
          item: "Campus Housing Room Condition - 2024-2025",
          link: "https://formstack.apu.edu/forms/rca_2024_2025",
        },
      ],
      afterLink: [
        {
          item: "APU CARES",
          link: "https://formstack.apu.edu/forms/apucareteam?apu_src=ug_student_page",
        },
        {
          item: "SGA Website",
          link: "https://apusga.com/?apu_src=ug_student_page",
        },
        {
          item: "ADR Access (Students)",
          link: "https://shibboleth-apu-accommodate.symplicity.com/sso/?apu_src=ug_student_page",
        },
      ],
    },
  ],
};

export default allPageData;
