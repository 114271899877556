import L, { LatLngBounds } from "leaflet";
import {
  ImageOverlay,
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import { useSearchParams } from "react-router-dom";
import uuid from "react-uuid";
import { TrolleyDataObject, TrolleysObject } from "#types/trolley";
import "./TrolleyTracker.css";
import devTrolleyData from "./devTrolleyData";

import ChristmasTheme, {
  chmasTrolleyIcon,
  chmasTrolleyIcon2,
} from "./ChristmasTheme";
import { hweenTrolleyIcon } from "./HolloweenTheme";
import easterRandomIcon from "./EasterTheme";
import { gradTrolleyIcon } from "./GraduationTheme";
import trolleyMap from "#images/maps/new_trolley_map_red_white_overlay.png";

interface TrolleysDataRaw {
  trolleysDataRaw: {
    Data: TrolleyDataObject[];
  };
  mapDefaultZoom: number;
}

interface TrolleysData {
  iconStyle: number;
  seasonEasterEgg: string;
  trolleysData: TrolleyDataObject[];
}

interface CenterZoom {
  center: [number, number];
  zoom: number;
}

let defaultIconWidth = 38;
// Set default theme
let mapStyle = "2a5bf32a-68eb-4b3d-a5e7-c86bbacaf844";
// Clear Easter Egg
let seasonEasterEgg = "none";

// Object for Trolly colors
const trolleyColorObject: TrolleysObject[] = [
  {
    color: "orange",
    colorHex: "#ff8502",
  },
  {
    color: "red",
    colorHex: "#990000",
  },
  {
    color: "silver",
    colorHex: "#afafaf",
  },
  {
    color: "green",
    colorHex: "#277c04",
  },
  {
    color: "white",
    colorHex: "#ffffff",
  },
];

// Calculate easter (https://gist.github.com/johndyer/0dffbdd98c2046f41180c051f378f343)
const flr = (num: number) => {
  return Math.floor(num);
};
const getEaster = (year: number) => {
  // Golden Number - 1
  const gNum = year % 19;
  const cNum = flr(year / 100);
  // Related to Epact
  const hNum =
    (cNum - flr(cNum / 4) - flr((8 * cNum + 13) / 25) + 19 * gNum + 15) % 30;
  // Number of days from 21 March to the Paschal full moon
  const iNum =
    hNum - flr(hNum / 28) * (1 - flr(29 / (hNum + 1)) * flr((21 - gNum) / 11));
  // Weekday for the Paschal full moon
  const jNum = (year + flr(year / 4) + iNum + 2 - cNum + flr(cNum / 4)) % 7;
  // Number of days from 21 March to the Sunday on or before the Paschal full moon
  const lNum = iNum - jNum;
  const month = 3 + flr((lNum + 40) / 44);
  const day = lNum + 28 - 31 * flr(month / 4);

  // Return easter as a date object
  const thisEaster = new Date(year, month - 1, day);
  return thisEaster;
};

const calcEasterEgg = (testMonth: number, testDate: number) => {
  // Show easter eggs for a given date range (month 0 = January)
  const today = new Date();
  if (testMonth) {
    today.setMonth(testMonth - 1);
    today.setDate(testDate);
  }

  // Add 3 weeks to easter for start date
  const thisEaster = new Date(getEaster(today.getFullYear()));
  const startEaster = new Date(getEaster(today.getFullYear()));
  startEaster.setDate(thisEaster.getDate() - 21);

  // Graduation Easter Egg icon 2 weeks before and 1 week after
  const startGrad = new Date(today.getFullYear(), 5 - 1, 4);
  const thisGrad = new Date(today.getFullYear(), 5 - 1, 4);
  startGrad.setDate(thisGrad.getDate() - 14);
  thisGrad.setDate(thisGrad.getDate() + 7);

  // Date based easter eggs
  if (today.getMonth() === 9) {
    // Show easter egg during October
    seasonEasterEgg = "Halloween";
    mapStyle = "b0ef78b4-0036-4dd4-830e-eeba31b8f1d2";
  } else if (today.getMonth() === 11) {
    // Show Christmas theme during December;
    seasonEasterEgg = "Christmas";
    mapStyle = "winter-v2";
    // Show Easter Theme during March
  } else if (today >= startEaster && today <= thisEaster) {
    seasonEasterEgg = "Easter";
    mapStyle = "511d9bf1-7e5c-4a8d-bd92-01d99ad339bb";
  } else if (today >= startGrad && today <= thisGrad) {
    seasonEasterEgg = "Graduation";
  }
};

// Return Trolly Marker icon
// Var - iconStyle
const getTrolleyIcon = (
  seasonEasterEgg: string,
  iconStyle: number,
  iconHex = "#3244c9",
) => {
  // Default Icon as SVG
  let trolleyIcon = `<svg data-name="Layer 1" version="1.1" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <title>trolley_mark_COLOR</title>
        <circle cx="8.92" cy="9" r="6.5" fill="none" stroke="#fff" stroke-width="4px" />
        <circle cx="8.92" cy="9" r="6.5" stroke-miterlimit="10" fill=${iconHex} stroke="#000" />
        <circle cx="8.92" cy="9" r="2" />
      </svg>`;

  switch (seasonEasterEgg) {
    case "Halloween":
      trolleyIcon = hweenTrolleyIcon(iconHex);
      break;
    case "Christmas":
      if (iconStyle === 1) {
        trolleyIcon = chmasTrolleyIcon(iconHex);
      } else {
        trolleyIcon = chmasTrolleyIcon2(iconHex);
      }
      break;
    case "Easter":
      trolleyIcon = easterRandomIcon(iconHex);
      break;
    case "Graduation":
      trolleyIcon = gradTrolleyIcon(iconHex);
      defaultIconWidth = 40;
      break;
  }

  const trolleyMarkReg = L.divIcon({
    html: trolleyIcon,
    iconSize: [defaultIconWidth, defaultIconWidth],
    iconAnchor: [defaultIconWidth / 2, defaultIconWidth / 2],
    popupAnchor: [0, (defaultIconWidth / 2) * -1],
  });

  return trolleyMarkReg;
};

// Create Marker Trolley Marker on location
// Vars - seasonEasterEgg
// Add iconStyle to getTrolleyIcon
const MapMarker = (props: TrolleysData) => {
  const pathBounds = [
    {
      // Bound 1: West Campus
      NW: L.latLng(34.135123, -117.90081),
      SE: L.latLng(34.13312, -117.8897),
    },
    {
      // Bound 2: Citrus Ave.
      NW: L.latLng(34.13312, -117.8903),
      SE: L.latLng(34.1318, -117.8897),
    },
    {
      // Bound 3: East Campus - North
      NW: L.latLng(34.13188, -117.8903),
      SE: L.latLng(34.12964, -117.88597),
    },
    {
      // Bound 4: East Campus - South
      NW: L.latLng(34.12994, -117.8903),
      SE: L.latLng(34.12787, -117.88356),
    },
    {
      // Bound 5: University Village (for the food truck)
      NW: L.latLng(34.1318, -117.89307),
      SE: L.latLng(34.12787, -117.8903),
    },
  ];

  const trolleysDataColor: TrolleyDataObject[] = [];

  props.trolleysData.forEach((data: TrolleyDataObject) => {
    const trolley = data;
    const name = trolley.VehicleName.toLowerCase();
    const trolleyCoords = L.latLng([
      trolley.LastStatusLat,
      trolley.LastStatusLon,
    ]);

    // Set the color attribute of each trolley object.
    trolleyColorObject.forEach((trolleyColor) => {
      if (name.includes(trolleyColor.color)) {
        trolley.Color = trolleyColor.color;
        trolley.ColorHex = trolleyColor.colorHex;
      } else if (name.includes("shuttle") || name.includes("bus")) {
        trolley.Color = "white";
        trolley.ColorHex = trolleyColor.colorHex;
      } else if (name.includes("truck")) {
        trolley.Color = "red";
        trolley.ColorHex = trolleyColor.colorHex;
      }
    });
    // Check if Trolley is within the bounds
    // Loop bounds and check trolley location
    pathBounds.forEach(function (path) {
      const bounds = L.latLngBounds(path.NW, path.SE);
      if (bounds.contains(trolleyCoords)) {
        trolleysDataColor.push(trolley);
      }
    });
  });

  // fill in the element
  const trolleyMarker = trolleysDataColor.map(
    (trolley: {
      VehicleName: string;
      VehicleId: number;
      LastStatusLat: number;
      LastStatusLon: number;
      Color?: string;
      ColorHex?: string;
    }) => (
      <Marker
        // key={trolley.VehicleId}
        key={uuid()}
        position={[trolley.LastStatusLat, trolley.LastStatusLon]}
        icon={getTrolleyIcon(
          props.seasonEasterEgg,
          props.iconStyle,
          trolley.ColorHex,
        )}
      >
        <Popup>
          I&apos;m the <b>{trolley.Color}</b> trolley!
        </Popup>
      </Marker>
    ),
  );

  return <>{trolleyMarker}</>;
};

const MyMap = (props: CenterZoom) => {
  const map = useMap();
  map.setView(props.center, props.zoom);
  return null;
};

// Vars - devMode snowColor
// Add iconStyle to MapMarker
const MaptilerLeaflet = (props: TrolleysDataRaw) => {
  // Get url params
  const [page, ,] = useSearchParams();
  const devMode = Boolean(page.get("dev") ?? false);
  const testMonth = Number(page.get("month"));
  const testDate = Number(page.get("day") ?? 15);
  const snowColor = Number(page.get("snow") ?? 0);
  const iconStyle = Number(page.get("icon") ?? 1);

  let trolleysDataRaw;
  // maptiler token TODO move to env file
  const accessToken = "8eatdYNztRnd5H45doKA";

  // Get EasterEggs
  calcEasterEgg(testMonth, testDate);

  // Compile url for map
  const mapURL = `https://api.maptiler.com/maps/${mapStyle}/{z}/{x}/{y}.png?key=${accessToken}`;

  // The coordinates where the map will be centered on the screen.
  const mapCenterCoords: [number, number] = [34.13285, -117.8933];

  const imageBounds = new LatLngBounds(
    [34.13716, -117.90233],
    [34.12707, -117.88137],
  );

  if (devMode) {
    trolleysDataRaw = devTrolleyData().Data;
  } else {
    trolleysDataRaw = props.trolleysDataRaw.Data;
  }

  let trolleysData: TrolleyDataObject[];
  // Filter out Trolleys that just started or just ended
  if (trolleysDataRaw) {
    trolleysData = trolleysDataRaw.filter(
      (vehicle: TrolleyDataObject) =>
        !(
          vehicle.LastStatus === null ||
          vehicle.LastStatus?.includes("Key Off") ||
          vehicle.LastStatus?.includes("Key On")
        ),
    );
  } else {
    trolleysData = [];
  }

  return (
    <MapContainer
      id="map"
      className="map"
      zoomControl={false}
      scrollWheelZoom={false}
      zoomSnap={0.5}
      zoomDelta={0.5}
    >
      <MyMap center={mapCenterCoords} zoom={props.mapDefaultZoom} />
      <TileLayer
        attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url={mapURL}
        tileSize={512}
        zoomOffset={-1}
        crossOrigin={true}
        minZoom={14}
        maxZoom={16}
      />
      <ImageOverlay url={trolleyMap} bounds={imageBounds} />
      <MapMarker
        trolleysData={trolleysData}
        seasonEasterEgg={seasonEasterEgg}
        iconStyle={iconStyle}
      />
      {seasonEasterEgg === "Christmas" ? (
        <ChristmasTheme snowColorID={snowColor} />
      ) : null}
    </MapContainer>
  );
};

export default MaptilerLeaflet;
