import { useState } from "react";
import { Buffer } from "buffer";
import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { ClipLoader } from "react-spinners";
import Barcode from "react-barcode";
import $ from "jquery";

import { defaultProfilePicture } from "#images/defaultProfilePicture";
import { APULogoObject } from "#components/APULogo";
import OopsPage from "#components/OopsPage";
import GradIcon from "#images/icons/icon-grad-circle.png";
import DiningPlanIcon from "#images/icons/dining-points.svg";
import CougarBucksIcon from "#images/icons/cougar-bucks-red.svg";
import "./VIDCard.css";

// Note, we will need to look up detailed OneCard Information As well
const endpoint =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api/v1/users"
    : "/api/v1/users";

const VIDCard = () => {
  const {
    isSuccess: userIsSuccess,
    isError: userIsError,
    data: userData,
  } = useQuery("userProfile", () => axios.get(`${endpoint}/profile`), {
    onError: (error: AxiosError) => {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
    },
    onSuccess: (userData) => {
      // Since staff and faculty tend to keep student personas
      // if they were previously students, we need to check if a
      // user is currently an employee as well as checking for
      // the student personas
      setIsStudent(
        !Object.hasOwn(userData.data, "jobtitle") &&
          (userData.data.PERSONAS.includes("STU") ||
            userData.data.PERSONAS.includes("UG") ||
            userData.data.PERSONAS.includes("GR")),
      );
      const image = Buffer.from(userData.data.PHOTO.data).toString("base64");
      const imageSrc = `data:image/jpeg;base64,${image}`;
      setProfilePicture(imageSrc);
    },
    staleTime: Infinity,
  });
  const { isSuccess: patronIsSuccess, data: patronData } = useQuery(
    "onecard",
    () => axios.get(`${endpoint}/onecard`),
    {
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          window.location.reload();
        }
      },
      onSuccess: (patronData) => {
        // At a minimum, everyone will have a cougar bucks account
        if (patronData.data.cougarBucksBalance) {
          setHasAccount(true);
          // The most minimal dining plan has flex dollars
          if (patronData.data.flexDollarsBalance) {
            setHasDiningPlan(true);
          }
        }
      },
      staleTime: Infinity,
    },
  );
  const [profilePicture, setProfilePicture] = useState(defaultProfilePicture);
  const [hasAccount, setHasAccount] = useState(false);
  const [hasDiningPlan, setHasDiningPlan] = useState(false);
  // It is safer to default to student being true, since this just results
  // in providing extra information to the user, which may not be relevant
  const [isStudent, setIsStudent] = useState(true);

  const AffilitationSection = () => {
    // If the user is a faculty and staff, return both of those
    if (
      userData?.data.PERSONAS.indexOf("FAC") >= 0 &&
      userData?.data.PERSONAS.indexOf("STAFF") >= 0
    ) {
      return (
        <>
          <p id="career">Faculty and Staff</p>
        </>
      );
    }
    // If the user is a faculty, show that
    else if (userData?.data.PERSONAS.indexOf("FAC") >= 0) {
      return (
        <>
          <p id="career">Faculty</p>
        </>
      );
    }
    // If the user is a staff, show that
    else if (userData?.data.PERSONAS.indexOf("STAFF") >= 0) {
      return (
        <>
          <p id="career">Staff</p>
        </>
      );
    }
    // Fall back if a user is an employee but missing specific Fac or Staff status
    else if (userData?.data.PERSONAS.indexOf("EMP") >= 0) {
      return (
        <>
          <p id="career">Faculty/Staff</p>
        </>
      );
    }
    // If the user has a career, return the descr of that career plus "student"
    else if (userData?.data.ACAD_CAREER_DESCR) {
      return (
        <>
          <p id="career">{userData.data.ACAD_CAREER_DESCR} Student</p>
        </>
      );
    }
    // Otherwise, leave the section blank (this really shouldn't happen)
    else {
      return <></>;
    }
  };

  const StudentSection = () => {
    // First, if the user doesn't have any academic plan, leave this blank
    if (!userData?.data.ACAD_PLANS_DESCR) {
      return <></>;
    }
    // Now, we can assume the user does have a major, so we can go from here
    else {
      // First, since a student can potentially have multiple plans, aka Majors and Minors
      // we split the comma delinated list into an array.
      const planDescrs = userData.data.ACAD_PLANS_DESCR.split(",");
      // Now, return the academic plans section
      return (
        <>
          <label>Academics</label>
          <div id="majors">
            {planDescrs.map((descr: string, i: number) => (
              <p id="major" key={i}>
                {descr}
              </p>
            ))}
          </div>
        </>
      );
    }
  };

  const EmployeeSection = () => {
    // First, if the user doesn't have a job title, leave this blank
    if (!userData?.data.jobtitle) {
      return <></>;
    }
    // Now, we can assume the user is an employee, so print their job information
    else {
      return (
        <>
          <label>Employment</label>
          <div id="jobtitles">
            <p id="jobtitle">{userData.data.jobtitle}</p>
            <p id="jobtitle">{userData.data.department}</p>
          </div>
        </>
      );
    }
  };

  const OneCardBarcode = () => {
    // First, if there is no onecard number available, return nothing
    if (!userData?.data.onecardNumber) {
      return <></>;
    }
    // If the barcode is defined, return that section
    else {
      return (
        <Barcode
          value={userData.data.onecardNumber}
          format="CODE39"
          displayValue={false}
          width={2}
          height={60}
        />
      );
    }
  };

  const CardFront = () => {
    // If there was an error fetching the user profile, return the oops page within the card
    if (userIsError) {
      return <OopsPage />;
    }
    // Else, if the data was fetched successfully, construct and return the card front
    else if (userIsSuccess) {
      return (
        <div className="card-face" id="front">
          <div id="upper">
            <img id="apu-logo" alt="APU Logo" src={APULogoObject.hzPNG} />
            {userData.data.ALUMNI === "Y" ? (
              <img id="alumn-icon" alt="Alumni Icon" src={GradIcon} />
            ) : (
              <></>
            )}
            <img
              id="profile-picture"
              alt="Profile Picture"
              src={profilePicture}
            />
            <div id="name-section">
              <p id="name">{userData.data.NAME}</p>
              <p id="netid">{userData.data.OPRID}</p>
              <AffilitationSection />
            </div>
          </div>
          <div id="lower">
            {userData.data?.jobtitle ? <EmployeeSection /> : <StudentSection />}
            <div id="barcode-section">
              <p id="emplid">ID: {userData.data.EMPLID}</p>
              <div id="barcode">
                <OneCardBarcode />
              </div>
            </div>
          </div>
        </div>
      );
    }
    // Otherwise, the card is still loading, so return a loading spinner
    else {
      return (
        <div className="card-face" id="front">
          <ClipLoader color={"#700"} size={50} />
        </div>
      );
    }
  };

  const CardBack = () => {
    // If the patron data has been succesfully loaded, generate the back of the VID
    if (patronIsSuccess) {
      // Return somewhat of an error screen for when the user does not have a OneCard account
      if (!hasAccount) {
        return (
          <div className="card-face" id="back">
            <div id="center">
              <img
                id="purchasing-power-img"
                alt="Purchasing Power Image"
                src={DiningPlanIcon}
              />
              <p className="no-account">You do not have a One Card account.</p>
              <p className="no-account-detail">
                If you feel that you have received this message in error, please
                contact the IMT Support Desk.
              </p>
              <p className="no-account-detail">Online at support.apu.edu</p>
              <p className="no-account-detail">Or by phone at (626) 815-5050</p>
            </div>
          </div>
        );
      } else {
        let mealString = "";
        if (
          hasDiningPlan &&
          patronData?.data.mealsBalance &&
          patronData?.data.guestMealsBalance
        ) {
          mealString = `My Meals: ${patronData.data.mealsBalance} Guest Meals: ${patronData.data.guestMealsBalance}`;
        } else if (hasDiningPlan && patronData?.data.mealsBalance) {
          mealString = `My Meals: ${patronData.data.mealsBalance}`;
        }
        return (
          <div className="card-face" id="back">
            {isStudent || hasDiningPlan ? (
              <div id="upper">
                <img
                  id="purchasing-power-img"
                  alt="Purchasing Power Image"
                  src={DiningPlanIcon}
                />
                {hasDiningPlan ? (
                  <>
                    <b>{patronData?.data.planName}</b>
                    <p className="balance">{`Flex Dollars: $${patronData?.data.flexDollarsBalance}`}</p>
                    <p className="balance">{mealString}</p>
                  </>
                ) : (
                  <>
                    <b>No Dining Plan</b>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}

            <div id={isStudent ? "lower" : "center"}>
              <img
                id="cougar-bucks-img"
                alt="cougar-bucks-img"
                src={CougarBucksIcon}
              />
              <b>Cougar Bucks</b>
              <p className="balance">${patronData?.data.cougarBucksBalance}</p>
            </div>
          </div>
        );
      }
    }
    // Otherwise, just leave the back blank (this should be temporary, until the data is loaded)
    else {
      return <div className="card-face" id="back" />;
    }
  };

  const flipVirtualID = () => {
    const card = $(".card-flip-animation-surface .card-contents");
    // If the card is currently not transformed, flip it by adding a transformation.
    // Otherwise, unflip it by removing the transformation.
    if (card.css("transform") == "none") {
      card.css({
        transform: "rotateY(180deg)",
      });
    } else {
      card.css({
        transform: "none",
      });
    }
  };

  return (
    <section id="virtual-id">
      <div
        className="card-flip-animation-surface"
        onClick={() => flipVirtualID()}
      >
        <div className="card-contents">
          <CardFront />
          <CardBack />
        </div>
      </div>
      <div id="suicide-lifeline-section">
        <p id="suicide-lifeline-label">National Suicide Prevention Lifeline </p>
        <a id="suicide-lifeline-number" href="tel:988">
          Call: 988
        </a>
      </div>
    </section>
  );
};

export default VIDCard;
