import { useState } from "react";
import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { ClipLoader } from "react-spinners";
import { OneCardPatron } from "#types/OneCard";

import Button from "#components/Button";
import OopsPage from "#components/OopsPage";
import CougarBucksIcon from "#images/icons/cougar-bucks-red.svg";
import DiningPlanIcon from "#images/icons/dining-points.svg";
import DiningHoursIcon from "#images/icons/icon_dining_hours.png";

import "./OneCard.css";

const endpoint =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api/v1/users"
    : "/api/v1/users";

// Interface types for objects
interface OneCardPatronProps {
  patronData?: {
    data: OneCardPatron;
  };
}

interface DisplayCardProps {
  hasAccount?: boolean;
  isStudent?: boolean;
  userIsSuccess?: boolean;
  hasDiningPlan?: boolean;
  patronData?: {
    data: OneCardPatron;
  };
}

const DisplayCard = (props: DisplayCardProps) => {
  if (props.hasAccount) {
    if (props.isStudent || !props.userIsSuccess || props.hasDiningPlan) {
      if (props.hasDiningPlan) {
        return (
          <>
            <DiningPlan patronData={props.patronData} />{" "}
            <CougarBucks patronData={props.patronData} />
          </>
        );
      } else {
        return (
          <>
            <NoDiningPlan /> <CougarBucks patronData={props.patronData} />
          </>
        );
      }
    } else {
      return <CougarBucks patronData={props.patronData} />;
    }
  } else {
    return <NoAccounts />;
  }
};

const DiningPlan = (props: OneCardPatronProps) => {
  let mealString = "";
  let mealReminderString: (string | JSX.Element)[] = [];

  if (
    props.patronData?.data.mealsBalance &&
    props.patronData?.data.guestMealsBalance
  ) {
    mealString = `My Meals: ${props.patronData.data.mealsBalance} Guest Meals: ${props.patronData.data.guestMealsBalance}`;
    mealReminderString = [
      `Your "My Meal" swipes will reset back to ${props.patronData?.data.diningMealsMax} each Sunday.`,
      <br key="1" />,
      `Your "Guest Meals" and "Flex Dollars" balances are for the whole semester.`,
    ];
  } else if (props.patronData?.data.mealsBalance) {
    mealString = `My Meals: ${props.patronData.data.mealsBalance}`;
    mealReminderString = [
      `Your "My Meal" swipes will reset back to ${props.patronData?.data.diningMealsMax} each Sunday.`,
      <br key="2" />,
      `Your "Flex Dollars" balances are for the whole semester.`,
    ];
  }

  return (
    <div className="account">
      <img className="image" src={DiningPlanIcon} alt="Dining Plan Icon" />
      <h1 className="account-name-dining">{props.patronData?.data.planName}</h1>
      <p className="current-balance">
        Flex Dollars: {props.patronData?.data.flexDollarsBalance}
      </p>
      <p className="current-balance">{mealString}</p>
      <div id="dining-points-difference">{mealReminderString}</div>
    </div>
  );
};

const CougarBucks = (props: OneCardPatronProps) => {
  return (
    <div className="account">
      <img className="image" src={CougarBucksIcon} alt="Cougar Bucks Icon" />
      <h1>Cougar Bucks</h1>
      <p className="current-balance" id="cougar-bucks-detail">
        ${props.patronData?.data.cougarBucksBalance}
      </p>
      <Button
        id="add-cougar-bucks"
        href="https://get.cbord.com/apu/full/login.php"
        name="Add Cougar Bucks"
      />
    </div>
  );
};

const NoDiningPlan = () => {
  return (
    <div className="account">
      <img className="image" src={DiningPlanIcon} alt="Dining Plan Icon" />
      <h1>Student Meal Plan</h1>
      <p className="expected-balance">
        No meal plan?
        <br />
        No problem!
      </p>
      <Button
        id="add-dining-points"
        href="https://formstack.apu.edu/forms/dining_plan_sign_up"
        name={["Sign up for a Meal Plan"]}
      />
    </div>
  );
};

const NoAccounts = () => {
  return (
    <div className="account">
      <img src={DiningPlanIcon} alt="Dining Plan Icon" />
      <h1>One Card</h1>
      <p className="balance-detail" id="dining-points-detail">
        You do not have a One Card account.
        <br />
        If you feel that you have received this message in error, please refresh
        this page. If the problem persists contact the IMT Support Center.
      </p>
      <Button
        id="support-link"
        href="https://support.apu.edu"
        name="Contact Support"
      />
    </div>
  );
};

const OneCard = () => {
  const { isSuccess: userIsSuccess } = useQuery(
    "userProfile",
    () => axios.get(`${endpoint}/profile`),
    {
      onSuccess: (userData) => {
        // Since staff and faculty tend to keep student personas
        // if they were previously students, we need to check if a
        // user is currently an employee as well as checking for
        // the student personas
        setIsStudent(
          !Object.hasOwn(userData.data, "jobtitle") &&
            (userData.data.PERSONAS.includes("STU") ||
              userData.data.PERSONAS.includes("UG") ||
              userData.data.PERSONAS.includes("GR")),
        );
      },
      staleTime: Infinity,
    },
  );
  const {
    isSuccess,
    isError,
    data: patronData,
  } = useQuery("onecard", () => axios.get(`${endpoint}/onecard`), {
    onError: (error: AxiosError) => {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
    },
    onSuccess: (patronData) => {
      // At a minimum, everyone will have a cougar bucks account
      if (patronData.data.cougarBucksBalance) {
        setHasAccount(true);
        // The most minimal dining plan has flex dollars
        if (patronData.data.flexDollarsBalance) {
          setHasDiningPlan(true);
        }
      }
    },
    staleTime: Infinity,
  });
  const [hasAccount, setHasAccount] = useState(false);
  const [hasDiningPlan, setHasDiningPlan] = useState(false);
  // It is safer to default to student being true, since this just results
  // in providing extra information to the user, which may not be relevant
  const [isStudent, setIsStudent] = useState(true);

  const showHours = () => {
    const hoursModal = document.getElementById("hours-modal");
    const modalBackground = document.getElementById("modal-background");
    if (hoursModal) hoursModal.style.display = "block";
    if (modalBackground) modalBackground.style.display = "block";
  };

  const closeHours = () => {
    const hoursModal = document.getElementById("hours-modal");
    const modalBackground = document.getElementById("modal-background");
    if (hoursModal) hoursModal.style.display = "none";
    if (modalBackground) modalBackground.style.display = "none";
  };

  if (isError) {
    return <OopsPage />;
  } else if (isSuccess) {
    return (
      <section id="onecard">
        <DisplayCard
          hasAccount={hasAccount}
          isStudent={isStudent}
          userIsSuccess={userIsSuccess}
          hasDiningPlan={hasDiningPlan}
          patronData={patronData}
        />
        <button
          id="hours-button"
          onClick={() => showHours()}
          onKeyDown={() => showHours()}
        >
          <img id="hours-image" src={DiningHoursIcon} alt="Dining Hours Icon" />
        </button>
        <div id="modal-background" className="modal">
          <div id="hours-modal" className="modal-content">
            <button
              className="close-button"
              onClick={() => closeHours()}
              onKeyDown={() => closeHours()}
            >
              &#xD7;
            </button>
            <iframe
              src="https://apu.cafebonappetit.com/#cafe-hours"
              title="Cafe Hours and Menu"
              id="hours-iframe"
            />
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <div className="cliploader-spinner">
        <ClipLoader color={"#700"} size={100} />
      </div>
    );
  }
};

export default OneCard;
