import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

const AppWrapper = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// When the app is server-side rendered, we need to use hydration
if (rootElement?.hasChildNodes()) {
  hydrateRoot(rootElement, <AppWrapper />);
} else if (rootElement) {
  // Otherwise, we are rendering the app locally
  const root = createRoot(rootElement);
  root.render(<AppWrapper />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
