import React, { useState, useEffect } from "react";
import MaptilerLeaflet from "./MaptilerLeaflet";
import "./TrolleyTracker.css";
import axios from "axios";
import noTrolleyData from "./noTrolleyData";
import { TrolleyDataObject } from "#types/trolley";
import { APULogoObject } from "#components/APULogo";

interface TrolleysApiData {
  StatusCode: string;
  Message: string;
  Data: TrolleyDataObject[];
}

const endpoint =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api/public/trolleytracker"
    : "/api/public/trolleytracker";

// Turn on dev mode from url params
const queryParams = new URLSearchParams(window.location.search);
const devBanner = Boolean(queryParams.get("banner") ?? false);
const version = "1.5.1";

const TrolleyTracker = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerHeight,
    window.innerWidth,
  ]);
  // const [mapDefaultZoom, setMapDefaultZoom] = useState(15);
  // Trolley Service Times:
  //  Monday-Thursday: 6:45am - 11:00pm
  //  Friday: 6:45am - 7:00pm
  // No Trolley Service on Tuesday: 3:00pm - 3:45pm
  const [message, setMessage] = useState("");
  const [count, setCount] = useState(1);
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");
  const [countUpdate, setCountUpdate] = useState(false);
  const [trolleyData, setTrolleyData] = useState(noTrolleyData());

  // Set variables for screen resolution and zoom level
  const [winHeight, winWidth] = windowSize;
  let mapDefaultZoom;

  const now = new Date();

  // Test banner
  // Tuesday Gap
  // const now = new Date(2023, 10, 28);
  // now.setHours(15, 1, 0);
  // Weekend
  // const now = new Date(2023, 10, 25);
  // Weekday After Hours
  // const now = new Date(2023, 10, 22);
  // now.setHours(23, 1, 0);
  // Friday After Hours
  // const now = new Date(2023, 10, 24);
  // now.setHours(19, 1, 0);

  const beginHours = new Date(now);
  const afterHours = new Date(now);
  const afterFridayHours = new Date(now);
  const beginGapTuesday = new Date(now);
  const stopGapTuesday = new Date(now);
  // The % returns only the remainder after division (0 and 6 are the only two out of 0-6 without a remainder when divided by 6: 0 = Sun, 6 = Sat)
  const isWeekend = now.getDay() % 6 === 0;
  // Set hours
  beginHours.setHours(6, 45, 0);
  afterHours.setHours(23, 0, 0);
  afterFridayHours.setHours(19, 0, 0);
  beginGapTuesday.setHours(15, 0, 0);
  stopGapTuesday.setHours(15, 45, 0);

  // Retrive location/other trolley data
  const getTrolleyData = async () => {
    const sorryMessage = "Sorry! Trolley Tracker is currently out of service!";
    // get trolley data from our server api
    axios
      .get<TrolleysApiData>(endpoint, {
        validateStatus: function (status) {
          return status < 300; // Resolve only if the status code is less than 300
        },
      })
      .then((res) => {
        if (message === sorryMessage) {
          setMessage("");
        }
        return setTrolleyData(res.data);
      })
      .catch(() => {
        // Temporary message while trolley tracker is out of service
        setMessage(sorryMessage);
      });
  };

  //
  useEffect(() => {
    setLastUpdatedTime(now.toLocaleTimeString());
    // Tuesday from 3:00pm - 3:45pm
    if (now.getDay() === 2 && now > beginGapTuesday && now < stopGapTuesday) {
      setMessage(
        `Sorry! There are currently no operating trolleys! Trolley Service will be back after 3:45pm!`,
      );
      // Weekday afterhours
    } else if (now > afterHours || now < beginHours) {
      setMessage(
        `Sorry! There are currently no operating trolleys! Trolley Service will start at 6:45am!`,
      );
    }
    // Weekend or Friday evening
    if (isWeekend || (now.getDay() === 5 && now > afterFridayHours)) {
      setMessage(
        `Sorry! There are currently no operating trolleys! Trolley Service will start back on Monday!`,
      );
    }

    // Test banner
    if (devBanner) {
      setMessage(
        `DEV MODE: ${version} ${now} Sorry! There are currently no operating trolleys! Trolley Service will start at 6:45am!`,
      );
    }
  }, []);

  // If there is no current message to show, message will be empty and the alert will not be shown
  const closeAlertMessage = () => {
    setMessage("");
  };

  const updateTimer = () => {
    if (count <= 1) {
      getTrolleyData();
      setCount(10);
      setLastUpdatedTime(now.toLocaleTimeString());
    } else {
      setCount(count - 1);
    }
    setTimeout(() => setCountUpdate(!countUpdate), 1000);
  };

  useEffect(() => {
    updateTimer();
  }, [countUpdate]);

  // Get screen resolution
  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowSize([window.innerHeight, window.innerWidth]);
    };
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  // Change zoom level based on resolution
  if (winWidth < 350 || winHeight < 330) {
    mapDefaultZoom = 14.5;
  } else if ((winWidth > 349 && winWidth < 730) || winHeight < 450) {
    mapDefaultZoom = 15;
  } else {
    mapDefaultZoom = 15.5;
  }

  return (
    <div id="trolley-tracker">
      {/* Conditionally show an alert banner-message with a $message string (currently undefined) */}
      {/* {message.length > 1 && message !== "HIDDEN" ? ( */}
      {message.length > 1 ? (
        <div className="custom-alert banner">
          <button
            id="closebtn"
            onClick={closeAlertMessage}
            onKeyDown={closeAlertMessage}
          >
            &#xD7;
          </button>
          <p>{message}</p>
        </div>
      ) : null}
      <img id="apu-logo" alt="APU Logo" src={APULogoObject.hzPNG} />

      {/* The div used by Leaflet and MapTiler to create the map */}
      <MaptilerLeaflet
        trolleysDataRaw={trolleyData}
        mapDefaultZoom={mapDefaultZoom}
      />

      {/* Additional information regarding trolley data and schedules */}
      <div id="tracking-info" className="tracking-info">
        As of {lastUpdatedTime}. Update in 00.
        {count.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
      </div>
    </div>
  );
};

export default TrolleyTracker;
