const noTrolleyData = () => {
  const noTrolleyObject = {
    StatusCode: "200",
    Message: "OK",
    Data: [
      {
        VehicleId: 27832,
        VehicleName: "APU-242389 (White bus 308)",
        TimeZone: "PST",
        LastStatus: "Key On (IS)",
        LastStatusTime: "09/12/2023 10:26:26",
        LastStatusLat: 34.13265,
        LastStatusLon: -117.88778,
        LastStatusAddress1: "6620 Citrus Ave",
        LastStatusCity: "Azusa",
        LastStatusState: "CA",
        LastStatusZip: "91702",
        LastStatusSpeed: "0.000",
        LastStatusCourse: "110.000",
        LastStatusReasonCode: "3",
        LastLocationTime: "09/14/2023 10:28:02",
        LastLocationLat: 34.13265,
        LastLocationLon: -117.88778,
        LastLocationAddress1: "6620 Citrus Ave",
        LastLocationCity: "Azusa",
        LastLocationState: "CA",
        LastLocationZip: "91702",
        LastLocationSpeed: "0.000",
        LastLocationCourse: "92.000",
        LastLocationReasonCode: "44",
      },
    ],
  };

  return noTrolleyObject;
};

export default noTrolleyData;
