const devTrolleyData = () => {
  const devTrolleyObject = {
    StatusCode: "200",
    Message: "OK",
    Data: [
      {
        VehicleId: 17517,
        VehicleName: "APU-024282 (Red Trolley)",
        TimeZone: "PST",
        LastStatus: "Leave (East Parking Trolley Stop)",
        LastStatusTime: "09/11/2023 07:20:53",
        LastStatusLat: 34.13348,
        LastStatusLon: -117.89252,
        LastStatusAddress1: "6620 Citrus Ave",
        LastStatusCity: "Azusa",
        LastStatusState: "CA",
        LastStatusZip: "91702",
        LastStatusSpeed: "0.000",
        LastStatusCourse: "270.000",
        LastStatusReasonCode: "2",
        LastLocationTime: "09/14/2023 07:20:38",
        LastLocationLat: 34.13274,
        LastLocationLon: -117.88737,
        LastLocationAddress1: "6620 Citrus Ave",
        LastLocationCity: "Azusa",
        LastLocationState: "CA",
        LastLocationZip: "91702",
        LastLocationSpeed: "0.000",
        LastLocationCourse: "270.000",
        LastLocationReasonCode: "44",
      },
      {
        VehicleId: 17520,
        VehicleName: "APU-024298 (Silver Trolley)",
        TimeZone: "PST",
        LastStatus: "East Parking Lot Trolley Stop",
        LastStatusTime: "09/14/2023 11:20:23",
        LastStatusLat: 34.13272,
        LastStatusLon: -117.88739,
        LastStatusAddress1: "6620 Citrus Ave",
        LastStatusCity: "Azusa",
        LastStatusState: "CA",
        LastStatusZip: "91702",
        LastStatusSpeed: "0.000",
        LastStatusCourse: "266.000",
        LastStatusReasonCode: "29",
        LastLocationTime: "09/14/2023 11:22:13",
        LastLocationLat: 34.13272,
        LastLocationLon: -117.88739,
        LastLocationAddress1: "6620 Citrus Ave",
        LastLocationCity: "Azusa",
        LastLocationState: "CA",
        LastLocationZip: "91702",
        LastLocationSpeed: "0.000",
        LastLocationCourse: "266.000",
        LastLocationReasonCode: "5",
      },
      {
        VehicleId: 17525,
        VehicleName: "APU-024321 (Green Trolley)",
        TimeZone: "PST",
        LastStatus: "Leave (Soccer Field Trolley Stop)",
        LastStatusTime: "09/14/2023 12:26:42",
        LastStatusLat: 34.13364,
        LastStatusLon: -117.90002,
        LastStatusAddress1: "582 E Foothill Blvd",
        LastStatusCity: "Azusa",
        LastStatusState: "CA",
        LastStatusZip: "91702",
        LastStatusSpeed: "0.000",
        LastStatusCourse: "176.000",
        LastStatusReasonCode: "",
        LastLocationTime: "09/14/2023 12:28:12",
        LastLocationLat: 34.13364,
        LastLocationLon: -117.90002,
        LastLocationAddress1: "582 E Foothill Blvd",
        LastLocationCity: "Azusa",
        LastLocationState: "CA",
        LastLocationZip: "91702",
        LastLocationSpeed: "0.000",
        LastLocationCourse: "176.000",
        LastLocationReasonCode: "",
      },
      {
        VehicleId: 17093,
        VehicleName: "APU-027828 (Orange Trolley)",
        TimeZone: "PST",
        LastStatus: "Key Off (IS)",
        LastStatusTime: "09/14/2023 09:42:14",
        LastStatusLat: 34.04948,
        LastStatusLon: -117.72226,
        LastStatusAddress1: "11222 Chico Ave",
        LastStatusCity: "Pomona",
        LastStatusState: "CA",
        LastStatusZip: "91766",
        LastStatusSpeed: "0.000",
        LastStatusCourse: "120.000",
        LastStatusReasonCode: "29",
        LastLocationTime: "09/14/2023 09:44:04",
        LastLocationLat: 34.04948,
        LastLocationLon: -117.72226,
        LastLocationAddress1: "11222 Chico Ave",
        LastLocationCity: "Pomona",
        LastLocationState: "CA",
        LastLocationZip: "91766",
        LastLocationSpeed: "0.000",
        LastLocationCourse: "120.000",
        LastLocationReasonCode: "5",
      },
      {
        VehicleId: 27832,
        VehicleName: "APU-242389 (White bus 308)",
        TimeZone: "PST",
        LastStatus: "Key On (IS)",
        LastStatusTime: "09/12/2023 10:26:26",
        LastStatusLat: 34.13265,
        LastStatusLon: -117.88778,
        LastStatusAddress1: "6620 Citrus Ave",
        LastStatusCity: "Azusa",
        LastStatusState: "CA",
        LastStatusZip: "91702",
        LastStatusSpeed: "0.000",
        LastStatusCourse: "110.000",
        LastStatusReasonCode: "3",
        LastLocationTime: "09/14/2023 10:28:02",
        LastLocationLat: 34.13265,
        LastLocationLon: -117.88778,
        LastLocationAddress1: "6620 Citrus Ave",
        LastLocationCity: "Azusa",
        LastLocationState: "CA",
        LastLocationZip: "91702",
        LastLocationSpeed: "0.000",
        LastLocationCourse: "92.000",
        LastLocationReasonCode: "44",
      },
    ],
  };
  const devTrolleyObject2 = {
    StatusCode: "200",
    Message: "OK",
    Data: [
      {
        VehicleId: 0,
        VehicleName: null,
        TimeZone: null,
        LastStatus: null,
        LastStatusTime: null,
        LastStatusLat: 0,
        LastStatusLon: 0,
        LastStatusAddress1: null,
        LastStatusCity: null,
        LastStatusState: null,
        LastStatusZip: null,
        LastStatusSpeed: null,
        LastStatusCourse: null,
        LastStatusReasonCode: null,
        LastLocationTime: null,
        LastLocationLat: 0,
        LastLocationLon: 0,
        LastLocationAddress1: null,
        LastLocationCity: null,
        LastLocationState: null,
        LastLocationZip: null,
        LastLocationSpeed: null,
        LastLocationCourse: null,
        LastLocationReasonCode: null,
      },
      {
        VehicleId: 0,
        VehicleName: null,
        TimeZone: null,
        LastStatus: null,
        LastStatusTime: null,
        LastStatusLat: 0,
        LastStatusLon: 0,
        LastStatusAddress1: null,
        LastStatusCity: null,
        LastStatusState: null,
        LastStatusZip: null,
        LastStatusSpeed: null,
        LastStatusCourse: null,
        LastStatusReasonCode: null,
        LastLocationTime: null,
        LastLocationLat: 0,
        LastLocationLon: 0,
        LastLocationAddress1: null,
        LastLocationCity: null,
        LastLocationState: null,
        LastLocationZip: null,
        LastLocationSpeed: null,
        LastLocationCourse: null,
        LastLocationReasonCode: null,
      },
    ],
  };

  // return devTrolleyObject2;
  return devTrolleyObject;
};

export default devTrolleyData;
